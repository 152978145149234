﻿(function($) {

    "use strict";

    if (typeof $.rsProto != "undefined") {
        // destroy method update
        var destroyOriginal = $.rsProto.destroy;
        $.rsProto.destroy = function () {
            var self = this;
            destroyOriginal.apply(this, arguments);

            // clean window resize timer
            if (self.resizeTimer) {
                clearTimeout(self.resizeTimer);
            }

            // TODO: other timers are not taken care of (#957 was cleared in updated ori version as well)

            // HOTFIX - RoyalSlider does not clear timers and because they can fire after plugin instance is destroyed, properties might no longer be accessible
            self.slides = [{ holder: null }];
            self.slider = { append: function () { } };
            self.ev = { trigger: function () { } };
        }

        // updateSliderSize method update
        var updateSliderSizeOriginal = $.rsProto.updateSliderSize;
        $.rsProto.updateSliderSize = function () {
            var self = this,
                currentHeight;

            if (self.slider && !self.slider.length) {
                return;
            }

            // remember height of hidden slider
            if (!self.slider.is(":visible") && typeof self.height != "undefined") {
                currentHeight = self.height;
            }

            // this is called after determining new dimensions of slider
            self.ev.off("rsAfterSizePropSet" + self.ns);
            self.ev.on("rsAfterSizePropSet" + self.ns, function () {
                // avoid unnecessary shrinking of hidden slider
                if (typeof currentHeight != "undefined" && self.height === 0) {
                    self.height = currentHeight;
                    self._wrapHeight = currentHeight;
                }
            });

            updateSliderSizeOriginal.apply(this, arguments);
        }
    }

})(jQuery);